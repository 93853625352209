import { Component, Prop, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfUser from "@/vue/domain/user/df-user";

@Component
export default class DfLinkButtonComponent extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  button!: DfContent;
  @Prop({ type: Boolean, default: false })
  textOnly!: boolean;

  get url(): string {
    const propertyUrl: string = Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_URL,
      "STRING"
    );
    return !propertyUrl.startsWith("http") && !propertyUrl.startsWith("/")
      ? `/${propertyUrl}`
      : propertyUrl;
  }

  get publicUrl(): string {
    const propertyUrl: string = Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_URL_PUBLIC,
      "STRING"
    );
    return !propertyUrl.startsWith("http") && !propertyUrl.startsWith("/")
      ? `/${propertyUrl}`
      : propertyUrl;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  get label(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_BUTTON_LABEL,
      "STRING"
    );
  }

  get backgroundColor(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_BUTTON_BACKGROUND_COLOR,
      "STRING"
    );
  }

  get color(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_BUTTON_COLOR,
      "STRING"
    );
  }

  get iconClass(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_BUTTON_ICON_CLASS,
      "STRING"
    );
  }

  get visibility(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_BUTTON_VISIBILITY,
      "STRING",
      ""
    );
  }

  get visibilityClass(): string {
    const cordova: any = (<any>window).cordova || null;
    const buttonVisibility = this.visibility;
    let buttonClass: string = null;

    if (cordova && buttonVisibility === "MOBILE") {
      buttonClass = "";
    } else if (cordova && buttonVisibility === "DESKTOP") {
      buttonClass = "desktop-in-cordova";
    } else {
      buttonClass = buttonVisibility.toLowerCase();
    }

    return buttonClass;
  }

  get buttonStyle(): any {
    const style: any = {};

    if (this.backgroundColor) {
      style.backgroundColor = this.backgroundColor;
    }

    if (this.color) {
      style.color = this.color;
    }

    return style;
  }

  get backgroundImage(): string {
    return Utils.getPropertyImageUrl(
      this.button,
      Utils.PROPERTY_BACKGROUND_IMAGE,
      null
    );
  }

  get showLabelMobile(): string {
    return Utils.getPropertyValue(
      this.button,
      Utils.PROPERTY_SHOW_LABEL_MOBILE,
      'BOOLEAN',
      true
    );
  }

  private onClick() {
    const privateAreaRequired: boolean = Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_AUTHENTICATED, "BOOLEAN", false);
    const privateAreaDisabled: boolean = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false);
    const unsubscribeNewsletterPage: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "PAG" && content.alias === Utils.PROPERTY_BRAND_AUTHENTICATED_NEWSLETTER_PAGE );

    this.$store.dispatch("setInitialRoute", null);
    if (privateAreaRequired && privateAreaDisabled) {
      if (this.publicUrl) {
        Utils.isExternalLink(this.publicUrl)
          ? window.open(this.publicUrl, "_blank")
          : this.$router.push({path: this.publicUrl});
      } else {
        console.error("Errore di configurazione: public Url mancante")
      }
    } else if (privateAreaRequired && !this.user) {
      this.$store.dispatch("setInitialRoute", this.$router.match(this.url));
      this.$store.dispatch("setShowLogin", true);
    } else if (this.user && unsubscribeNewsletterPage.length > 0 && this.url === "/newsletter") {
      this.$router.push({ path: `/contenuti/${unsubscribeNewsletterPage[0].alias}` });
    } else if (this.url) {
      Utils.isExternalLink(this.url)
        ? window.open(this.url, "_blank")
        : this.$router.push({ path: this.url });
    }
  }
}
